import PubSub from 'Services/pubsub/core__pubsub';
import { updateOdds } from './core__PopularBetsSocketUpdates';
/**
 * subscribe to markets by market id
 * @param {Object} marketId, markets array with details
 */
export const subscribeMarket = marketId => {
  PubSub.emit('ws:sub:market', marketId);
};

/**
 * function listens to websocket market data and update selections/odds
 */
export const listenMarket = dispatch => {
  return PubSub.listen('ws:market', wsData => {
    dispatch(updateOdds(wsData));
  });
};
