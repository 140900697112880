import styled from 'styled-components';
import I from 'UI/globals/Icons';
import { GREYS, FONT } from 'UI/globals/colours';

export const ItemContainer = styled.div`
  width: 305px;
  padding: 16px;
  background-color: ${GREYS.silverD};
  color: ${GREYS.white};
  padding-right: 0;
`;
export const Event = styled.a`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: ${GREYS.white};
  font-weight: 700;
  margin-bottom: 4px;
`;

export const EventName = styled.p`
  display: flex;
`;
export const Icon = styled(I)`
  margin-right: 8px;
  margin-top: 1px;
  color: ${({ theme }) => (theme.dark ? FONT.secondary : FONT.primary)};
`;
export const Title = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 240px;
`;

export const RightArrow = styled.i`
  border: solid ${FONT.secondary};
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

export const SelectionName = styled.p`
  font-size: 12px;
  margin-bottom: 12px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
export const ItemContainerInner = styled.div`
  border-right: 1px solid
    ${({ theme }) => (theme.dark ? FONT.dark : FONT.lightMute)};
  padding-right: 14px;
`;
