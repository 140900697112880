import PropTypes from 'prop-types';
import React from 'react';
import { getIcon } from 'Services/icons/core__icons';
import { B } from 'UI/apps/PopularBets/PopularBets';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import {
  ItemContainer,
  Event,
  EventName,
  Icon,
  Title,
  RightArrow,
  SelectionName,
  ItemContainerInner,
} from 'UI/apps/PopularBets/CreateABet';
import { replaceSpecialCharacter } from 'Services/globalfunctions/core__global-functions';
import {
  PopularBetSvg,
  SvgWrapper,
} from 'CORE__UI/apps/PopularBets/core__popularBetsReact';
import project from '../../../../project';
import { getOdds } from 'Services/global/core__american-format';

export const CreateABet = ({
  categoryRef,
  eventName,
  selection,
  selectedOdds,
  callBetslip,
  betSelections,
  url,
  showFeaturedIcon,
  theme,
}) => {
  const iconName = getIcon(categoryRef);

  const parseMetadata = metadataString => {
    const match = metadataString?.match(/\{decimal=(.*?), fractional=(.*?)\}/);
    if (match) {
      return { decimal: parseFloat(match[1]), fractional: match[2] };
    } else {
      return null;
    }
  };

  const oddSelector = (sel, typeOfOdds) => {
    if (!sel || !typeOfOdds) return;

    if (sel?.price?.length <= 0) return;

    const metadata = sel.metadata?.find(m => m.name === 'BOOST_METADATA');
    let parsedMetadata;
    if (metadata) {
      parsedMetadata = parseMetadata(metadata.value);
    }
    if (
      typeOfOdds === GLOBAL_CONSTANTS.FRACTIONAL &&
      sel?.price[0].fractional
    ) {
      if (parsedMetadata?.fractional) {
        return parsedMetadata.fractional + ' >> ' + sel.price[0].fractional;
      } else {
        return sel.price[0].fractional;
      }
    } else if (
      typeOfOdds === GLOBAL_CONSTANTS.DECIMAL &&
      sel?.price[0].decimal
    ) {
      if (parsedMetadata?.decimal) {
        return (
          parseDecimalPlaces(parsedMetadata.decimal) +
          ' >> ' +
          parseDecimalPlaces(sel?.price[0].decimal)
        );
      } else {
        return parseDecimalPlaces(sel?.price[0].decimal);
      }
    } else if (
      typeOfOdds === GLOBAL_CONSTANTS.AMERICAN &&
      sel?.price[0].decimal
    ) {
      return getOdds(sel.price[0].decimal, true);
    } else {
      return;
    }
  };

  return (
    <ItemContainer>
      <ItemContainerInner>
        <Event href={url}>
          <EventName>
            {showFeaturedIcon ? (
              <SvgWrapper>
                <PopularBetSvg theme={theme} />
              </SvgWrapper>
            ) : (
              <Icon
                bespokeSvgSportsIcons={project.bespokeSvgSportsIcons}
                category={categoryRef}
                iconName={iconName}
                marginRight={8}
              />
            )}
            <Title>{eventName}</Title>
          </EventName>
          <span>
            <RightArrow />
          </span>
        </Event>
        {selection?.map((sel, sei) => {
          return (
            <React.Fragment key={'selection' + sei}>
              <SelectionName>
                {sel?.name && replaceSpecialCharacter(sel.name)}
              </SelectionName>
              <B
                size={'default'}
                odds={oddSelector(sel, selectedOdds?.toLowerCase())}
                selected={betSelections?.indexOf(sel.id) > -1}
                onClick={() => {
                  sel.active && callBetslip(sel?.id);
                }}
                inactive={!sel.active || !sel.price}
                oddsTrend={sel?.oddsStatus}
              />
            </React.Fragment>
          );
        })}
      </ItemContainerInner>
    </ItemContainer>
  );
};

CreateABet.propTypes = {
  betSelections: PropTypes.array,
  callBetslip: PropTypes.func,
  categoryRef: PropTypes.any,
  eventName: PropTypes.any,
  selectedOdds: PropTypes.string,
  selection: PropTypes.array,
  url: PropTypes.any,
  showFeaturedIcon: PropTypes.bool,
  theme: PropTypes.string,
};
