import React from 'react';
import styled from 'styled-components';
import { BRAND, GREYS, FONT, CARD } from 'UI/globals/colours';
import OddsButton from 'UI/buttons/OddsButton';
import PropTypes from 'prop-types';
//PB refers to popular bet main div
export const PB = styled.div`
  margin-block: 0px 8px;
  .slick-arrow {
    top: 100px;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    width: 304px;
  }
`;
//PB refers to popular bet Title
export const BT = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  max-height: 48px;
  line-height: 20px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  padding: 12px;
`;

export const SvgWrapper = styled.div`
  margin-right: 8px; ;
`;
//BC refers to popular bet Carousel
export const BC = styled.div``;
//PE refers to popular bet Event
export const PE = styled.div`
  height: 210px;
  width: 304px;
  border-radius: 4px;
  box-shadow: ${CARD.boxShadow};
  background-color: ${({ theme }) =>
    theme.dark ? FONT.darkMute : GREYS.white};
`;
//PE refers to popular bet Event Data
export const ED = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
//LT refers to popular bet league Title
export const LT = styled.span`
  color: ${BRAND.primary};
`;
//LT refers to popular bet Market Title
export const LN = styled.span`
  color: ${({ theme }) => (theme.dark ? FONT.lightMute : GREYS.doveD)};
  padding: 8px 0 8px 0;
`;
//LT refers to popular bet event Title
export const OT = styled.span`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  padding-bottom: 4px;
`;
//LT refers to popular bet league Data
export const LD = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`;
export const B = styled(OddsButton)`
  flex: unset;
`;

export const svgIcon = { color: BRAND.primary };

export const PopularBetSvg = ({ theme, customColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            fill={theme === 'dark' ? BRAND.secondary : customColor}
            d="M11.3805 21.6337L17.2395 9.91575L13.1835 9.91575L13.1835 2.36625L7.212 14.0842H11.3813L11.3813 21.6337H11.3805ZM12 0C15.305 0 18.1313 1.17375 20.4788 3.52125C22.8263 5.86875 24 8.695 24 12C24 15.305 22.8263 18.1313 20.4788 20.4788C18.1313 22.8263 15.305 24 12 24C8.695 24 5.86875 22.8263 3.52125 20.4788C1.17375 18.1313 0 15.305 0 12C0 8.695 1.17375 5.86875 3.52125 3.52125C5.86875 1.17375 8.695 0 12 0Z"
          />
        </g>
      </g>
    </svg>
  );
};

PopularBetSvg.propTypes = {
  theme: PropTypes.string,
  customColor: PropTypes.string,
};
