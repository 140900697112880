import { getPopularBets } from './core__popular-bets-store';
import { MARKET_CONST as MARKET_CONST_LOCAL } from './component/config/CarouselConfig';
import { MARKET_CONST as MARKET_CONST_CORE } from 'CORE__UI/apps/PopularBets/core__carousel-config';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getComponent } from 'Services/core__imports';

const MARKET_CONST = getComponent(MARKET_CONST_LOCAL, MARKET_CONST_CORE);

/**
 * function to update odds and dispatch to store
 */
export function updateOdds(updatedData) {
  return (dispatch, getState) => {
    const stateData = getState();

    const eventDetails = stateData.dataReducer.popularBets;

    for (const market of eventDetails) {
      if (market?.marketName === updatedData?.name) {
        for (const selection of market?.selection) {
          for (const updatedSel of updatedData?.selections) {
            if (
              selection?.id === updatedSel?.id ||
              selection?.name === updatedSel?.name
            ) {
              if (
                updatedSel?.price?.bookType === GLOBAL_CONSTANTS.PRICE &&
                updatedSel?.price?.decimal
              ) {
                if (selection.price.length > 0) {
                  if (
                    selection?.price[0]?.decimal > updatedSel?.price?.decimal
                  ) {
                    selection['oddsStatus'] =
                      MARKET_CONST.ODDS_UPDATE.ODDS_DECREASE;
                  } else if (
                    selection?.price[0]?.decimal < updatedSel?.price?.decimal
                  ) {
                    selection['oddsStatus'] =
                      MARKET_CONST.ODDS_UPDATE.ODDS_INCREASE;
                  } else if (
                    selection?.price[0]?.decimal === updatedSel?.price?.decimal
                  ) {
                    selection['oddsStatus'] = null;
                  }
                  selection.price[0]['decimal'] = updatedSel.price.decimal;
                  selection.price[0]['fractional'] =
                    updatedSel.price.fractional;
                }
              }
            }
          }
        }
      }
    }

    dispatch(getPopularBets(JSON.parse(JSON.stringify(eventDetails))));
    setTimeout(() => {
      for (const updatedSel of updatedData?.selections) {
        for (const market of eventDetails) {
          if (market?.marketName === updatedData?.name) {
            if (Array.isArray(market?.selection)) {
              for (const selection of market?.selection) {
                if (
                  updatedSel?.id === selection?.id ||
                  selection?.name === updatedSel?.name
                ) {
                  if (updatedSel?.price?.bookType === GLOBAL_CONSTANTS.PRICE) {
                    selection['oddsStatus'] = null;
                  }
                }
              }
            }
          }
        }
      }
      dispatch(getPopularBets(JSON.parse(JSON.stringify(eventDetails))));
    }, 1000);
  };
}
