import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest } from 'Services/http/core__axios';
import { emitSelectionIdForBetSlip } from '../EventsApp/component/core__eventsFunction';
import { getCookie } from 'Services/cookie/core__cookies';
import { subscribeMarket, listenMarket } from './core__popularBets-wsData';
import moment from 'moment';
import { sortObjectArrayByKey } from 'Services/globalfunctions/core__global-functions';

const initialStateTemplate = {
  data: {
    popularBets: [],
    oddsFormat: '',
    betSelections: [],
  },
};
export const dataReducer = (state = [], action) => {
  switch (action.type) {
    case 'POPULAR_BETS':
      return {
        ...state,
        popularBets: action.popularBets,
      };
    case 'oddsFormat':
      return {
        ...state,
        selectedOdds: action.selectedOdds,
      };
    case 'BETSLIP_SELECTIONS':
      return {
        ...state,
        betSelections: action.betSelections,
      };
    default:
      return state;
  }
};

export const getOdds = selectedOdds => ({
  type: 'oddsFormat',
  selectedOdds,
});

export const getStoredBets = betSelections => ({
  type: 'BETSLIP_SELECTIONS',
  betSelections,
});

export const callBetslip = selection => () => {
  emitSelectionIdForBetSlip(selection);
};
/**
 * Featches market data from API.
 * @param {String} url
 * @param {function} nodeRequest
 */
export const fetchData = (url, nodeRequest, appConfig) => dispatch => {
  const promises = [];
  promises.push(getEventDetails(nodeRequest, appConfig, dispatch));
  return Promise.all(promises);
};
export const getEventDetails = (nodeRequest, appConfig, dispatch) => {
  const {
    catref,
    markets,
    startts,
    endts,
    featuredselections,
    featuredsort,
  } = appConfig;

  let domain;
  if (nodeRequest) {
    domain = '.json';
  } else {
    domain = '.fsb';
  }

  let base = `/fsb-api-rest/bet/category`;
  if (catref) {
    base += `/${catref}`;
  }

  base += `${domain}?metadata=true`;

  if (markets) {
    base += `&detail=${markets}`;
  }

  if (startts) {
    const scheduledFromTS =
      moment(new Date(startts)).format('YYYYMMDDTHHmmss') + 'Z';
    base += `&scheduledFrom=` + scheduledFromTS;
  }
  if (endts) {
    const scheduledToTS =
      moment(new Date(endts)).format('YYYYMMDDTHHmmss') + 'Z';
    base += `&scheduledTo=` + scheduledToTS;
  }
  if (featuredselections) {
    base += `&featuredSelections=${featuredselections}`;
  }

  if (appConfig.marketalbumref) {
    base += `&marketAlbumRef=${appConfig.marketalbumref}`;
  }
  if (appConfig.eventlimit && !isNaN(+appConfig.eventlimit)) {
    base += `&limit=${appConfig.eventlimit}`;
  }
  if (!markets) {
    base += `&allDetail=true`;
  }

  const url = `${base}`;
  const requester = !nodeRequest ? getRequest : nodeRequest;
  let finalData = [];
  const marketIds = [];
  const finalObject = {};
  return requester(url).then(res => {
    if (res?.category?.length > 0) {
      if (
        res.marketOrder &&
        appConfig.marketalbumref &&
        appConfig.sortby === 'marketOrder'
      ) {
        const marketOrder = res['marketOrder'].split(',');
        marketOrder?.forEach(mrkt => {
          finalObject[mrkt + '_a'] = [];
        });
      }
      res.category.forEach(cat => {
        cat?.subcat?.forEach(subcat => {
          subcat?.event?.forEach(event => {
            event?.market?.forEach(market => {
              marketIds.push(market.id);
              if (appConfig.type === 'lowest') {
                const selection = market?.selection.reduce((acc, s) =>
                  acc.price[0].decimal < s.price[0].decimal ? acc : s
                );

                finalData.push({
                  name: subcat.name,
                  id: subcat.id,
                  eventName: event.name,
                  scheduledStart: event.scheduledStart,
                  eventState: event.state,
                  eventId: event.id,
                  marketName: market.name,
                  marketId: market.id,
                  marketTypeRef: market.typeRef,
                  selection: [selection],
                  tabTitle: appConfig?.title,
                });
                return;
              } else if (appConfig?.type?.indexOf('_selections') >= 0) {
                const index = parseInt(appConfig.type.split('_')[0]);
                let selections = market?.selection.sort(
                  (a, b) => a.ordinal - b.ordinal
                );
                selections = selections.filter((s, i) => i < index);

                finalData.push({
                  name: subcat.name,
                  id: subcat.id,
                  eventName: event.name,
                  scheduledStart: event.scheduledStart,
                  eventState: event.state,
                  eventId: event.id,
                  marketName: market.name,
                  marketId: market.id,
                  marketTypeRef: market.typeRef,
                  selection: selections,
                  tabTitle: appConfig?.title,
                });
                return;
              } else if (appConfig.iscreateabet) {
                const selections = market?.selection.sort(
                  (a, b) => a.ordinal - b.ordinal
                );
                selections &&
                  selections.forEach(sel => {
                    const marketObject = {
                      catRef: cat.ref,
                      subcatRef: subcat.ref,
                      name: subcat.name,
                      id: subcat.id,
                      eventName: event.name,
                      scheduledStart: event.scheduledStart,
                      eventState: event.state,
                      eventId: event.id,
                      marketName: market.name,
                      marketId: market.id,
                      marketTypeRef: market.typeRef,
                      selection: [sel],
                      tabTitle: appConfig?.title,
                    };
                    if ((featuredsort && !sel.featured) || !sel.active) {
                      return; //exclude
                    }
                    if (
                      res.marketOrder &&
                      appConfig.marketalbumref &&
                      appConfig.sortby === 'marketOrder'
                    ) {
                      finalObject[market.id + '_a'] &&
                        finalObject[market.id + '_a'].push(marketObject);
                    } else {
                      finalData.push(marketObject);
                    }
                  });
              }
            });
          });
        });
      });
      //finalObject values will be empty in case sorting is not by marketOrder
      Object.values(finalObject).forEach(val => finalData.push(...val));
    }
    if (marketIds.length > 0) {
      subscribeMarket(marketIds);
      listenMarket(dispatch);
    }
    //if sorting required
    if (Array.isArray(finalData) && appConfig.sortby !== 'marketOrder') {
      finalData = sortObjectArrayByKey(finalData, 'scheduledStart');
    }
    dispatch(getPopularBets(finalData));
  });
};
export const getPopularBets = popularBets => ({
  type: 'POPULAR_BETS',
  popularBets,
});

export const getOddsFormat = oddsformat => dispatch => {
  let oddsFormat;
  if (oddsformat) {
    oddsFormat = oddsformat;
  } else {
    oddsFormat = getCookie('selectedOdds')
      ? getCookie('selectedOdds')
      : 'fractional';
  }
  dispatch(getOdds(oddsFormat));
};
export const getBetsInfo = data => dispatch => {
  dispatch(getStoredBets(data));
};

export const reducer = combineReducers({
  data: dataReducer,
});

const composeEnhancers = getComposeEnhancers({ name: 'popular-bets-store' });

export default initialState => {
  initialState = Object.assign(initialStateTemplate, initialState);
  return createStore(
    reducer,
    initialState ? initialState : initialStateTemplate,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
};
