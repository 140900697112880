import React, { useEffect, useState, useCallback } from 'react';
import { ScrollCarousel } from 'Services/carousel/core__scroll-carousel';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { carouselConfig as carouselConfig_LOCAL } from './config/CarouselConfig';
import { carouselConfig as carouselConfig_CORE } from 'CORE__UI/apps/PopularBets/core__carousel-config';

import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PB,
  BT,
  BC,
  PE,
  ED,
  LT,
  LN,
  OT,
  LD,
  B,
  svgIcon as svgIcon_LOCAL,
} from 'UI/apps/PopularBets/PopularBets';
import { Translations } from '../core__popular-bets-app';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { FSBTheme } from 'Services/core__fsb-theme';
import project from '../../../../project';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { CreateABet } from './core__popularbet-create-a-bet';
import {
  SvgWrapper,
  PopularBetSvg,
  svgIcon as svgIcon_CORE,
} from 'CORE__UI/apps/PopularBets/core__popularBetsReact';
import { SportsbookUrlService } from 'Services/url-amendments/core__url-amendments';
import { getComponent } from 'Services/core__imports';

const svgIcon = getComponent(svgIcon_LOCAL, svgIcon_CORE);
const carouselConfig = getComponent(carouselConfig_LOCAL, carouselConfig_CORE);
/**
 *
 * @param {appConfig} param0 refers to config data from cms
 * @param {fetchData} param1 refers to get data from store with appconfig url
 * @param {popularBets} param2 refers to array filtered data from api
 * @param {selectedOdds} param3 refers to odds selection
 * @param {getBetsInfo} param4 refers method to get the selected bets info
 * @param {getOddsFormat} param5 refers get the odds format from pubsub
 * @param {betSelections} param6 refers to config data from cms
 * @returns
 */
const PopularBets = ({
  appConfig,
  popularBets,
  selectedOdds,
  getBetsInfo,
  getOddsFormat,
  callBetslip,
  betSelections,
  fetchData,
  nodeRequest,
}) => {
  const theme = appConfig?.theme;
  const [carouselArrowState, setCarouselArrowState] = useState(false);

  const fetchPopularBets = useCallback(() => {
    if (popularBets.length === 0) {
      fetchData('', nodeRequest, appConfig);
    }

    // Get Odds format
    getOddsFormat();
    const oddValueListener = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      getOddsFormat(wsData);
    });
    // PubSub event listen to get selections added in betslip
    const eventsAppUpdateListener = PubSub.listen(
      PubsubEvents.EventsAppBetslipUpdate,
      wsData => {
        getBetsInfo(wsData);
      }
    );

    return () => {
      oddValueListener.unsubscribe();
      eventsAppUpdateListener.unsubscribe();
    };
  }, [
    appConfig,
    fetchData,
    getBetsInfo,
    getOddsFormat,
    nodeRequest,
    popularBets.length,
  ]);

  useEffect(() => {
    // cleanup gets returned
    return fetchPopularBets();
  }, [fetchPopularBets]);

  const settings = carouselConfig();
  if (!popularBets.length) {
    return null;
  }

  const filteredBets = popularBets.filter(
    pe =>
      pe.eventState === 'SCHEDULED' ||
      (appConfig.showinplay && pe.eventState === 'IN_PLAY')
  );

  return (
    <FSBTheme theme={theme}>
      <PB>
        {popularBets?.length > 0 && !appConfig.hidefeaturedtab && (
          <BT>
            <SvgWrapper>
              <PopularBetSvg theme={theme} customColor={svgIcon.color} />
            </SvgWrapper>
            {Translations.get(appConfig.title)}
          </BT>
        )}
        <BC
          data-test={'test-hover'}
          onMouseOver={() => setCarouselArrowState(true)}
          onMouseLeave={() => setCarouselArrowState(false)}
        >
          <ScrollCarousel
            {...settings}
            arrows={carouselArrowState}
            rightMargin={0}
          >
            {filteredBets?.map((pe, pei) => {
              if (appConfig.iscreateabet) {
                let url = ``;
                if (pe.name === 'Future Racing') {
                  // for Future Racing subcat is  FUTURE_RACES
                  url = `/sportsbook/${pe.catRef}/${GLOBAL_CONSTANTS.FUTURE_RACES}/${pe.eventId}/`;
                } else {
                  url = `/sportsbook/${pe.catRef}/${pe.subcatRef}/${pe.eventId}/`;
                  if (appConfig.urlamendments) {
                    const urlData = {
                      categoryRef: pe.catRef,
                      eventName: pe.eventName,
                      eventId: pe.eventId,
                      subcategoryId: pe.id,
                      subcategoryName: pe.name,
                    };
                    url = SportsbookUrlService.createUrlForTheBrowser(urlData);
                  }
                }
                return (
                  <CreateABet
                    categoryRef={pe.catRef}
                    eventName={pe?.eventName}
                    selection={pe?.selection}
                    eventState={pe?.eventState}
                    selectedOdds={selectedOdds}
                    callBetslip={callBetslip}
                    betSelections={betSelections}
                    marketName={pe.marketName}
                    url={url}
                    showFeaturedIcon={appConfig.showfeaturedicon}
                    theme={theme}
                    key={'create-bet' + pei}
                  />
                );
              } else {
                return (
                  <PE
                    key={'carousel' + pei}
                    data-test={pei === 0 && 'pop-carouseldata'}
                  >
                    <ED>
                      <LD>
                        <LT>{pe?.name}</LT>
                        <LT>
                          {moment(pe?.scheduledStart).format(
                            project.timeFormats.search.tertiary || 'HH:MM'
                          )}
                        </LT>
                      </LD>

                      <OT> {pe?.eventName?.split(' v ')[0]}</OT>
                      <OT> {pe?.eventName?.split(' v ')[1]}</OT>
                      <LN> {pe?.marketName}</LN>
                      <div
                        style={{
                          display: pe?.selection?.length > 1 ? 'flex' : 'block',
                        }}
                      >
                        {pe?.selection?.map((sel, sei) => {
                          return (
                            <B
                              data-test={'betslip-test'}
                              size={'default'}
                              key={'selection' + sei}
                              label={
                                sel?.name &&
                                !appConfig.noselectionname &&
                                sel.name
                              }
                              odds={
                                sel?.price?.length > 0 &&
                                sel?.price[0].fractional &&
                                selectedOdds?.toLowerCase() ===
                                  GLOBAL_CONSTANTS.FRACTIONAL
                                  ? sel?.price?.length > 0 &&
                                    sel?.price[0].fractional
                                  : sel?.price?.length > 0 &&
                                    sel?.price[0].decimal &&
                                    selectedOdds?.toLowerCase() ===
                                      GLOBAL_CONSTANTS.DECIMAL
                                  ? sel?.price?.length > 0 &&
                                    parseDecimalPlaces(sel?.price[0].decimal)
                                  : 'SP'
                              }
                              selected={betSelections?.indexOf(sel.id) > -1}
                              onClick={() => {
                                pe?.eventState !== 'COMPLETED' &&
                                  callBetslip(sel?.id);
                              }}
                              inactive={pe?.eventState === 'COMPLETED'}
                              oddsTrend={sel?.oddsStatus}
                            />
                          );
                        })}
                      </div>
                    </ED>
                  </PE>
                );
              }
            })}
          </ScrollCarousel>
        </BC>
      </PB>
    </FSBTheme>
  );
};

PopularBets.propTypes = {
  appConfig: PropTypes.object,
  getOddsFormat: PropTypes.func,
  getBetsInfo: PropTypes.func,
  selectedOdds: PropTypes.string,
  callBetslip: PropTypes.func,
  betSelections: PropTypes.array,
  fetchData: PropTypes.func,
  popularBets: PropTypes.array,
  nodeRequest: PropTypes.any,
};
export default PopularBets;
