import styled from 'styled-components';
import { BREAKPOINT_S } from '../../globals/breakpoints';
import { GREYS, BRAND } from '../../globals/colours';
import {
  PB,
  BT,
  BC,
  PE,
  ED,
  LT,
  LN,
  OT,
  LD,
  B,
} from 'CORE__UI/apps/PopularBets/core__popularBetsReact';

const LT_S = styled(LT)`
  color: ${GREYS.white};
  @media screen and (max-width: ${BREAKPOINT_S}) {
    min-height: 75px;
  }
`;

const BT_S = styled(BT)`
  color: ${BRAND.secondary};
`;

const PE_GW = styled(PE)`
  background-color: ${GREYS.silverD};
`;

const PB_EX = styled(PB)`
  @media (max-width: ${BREAKPOINT_S}) {
    margin-block: 8px;
  }
`;

export {
  PB_EX as PB,
  BT_S as BT,
  BC,
  PE_GW as PE,
  ED,
  LT_S as LT,
  LN,
  OT,
  LD,
  B,
};
