import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import createStore, {
  getEventDetails,
  getBetsInfo,
  getOddsFormat,
  callBetslip,
  fetchData,
} from './core__popular-bets-store';
import ScDecoder from 'Services/json/core__decoder';
import PopularBets from './component/core__popularBetsReact';
import { stringsToTranslate } from './core__popular-bets-translations';
import { TranslationsProvider } from 'Services/translations/core__translations';
export const Translations = new TranslationsProvider('PopularBetsApp');
class PopularBetsApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations?.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations?.getAll?.bind(
        properties.Translations
      );
      return;
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = state => ({
      appConfig: this.appConfig,
      popularBets: state.data.popularBets,
      selectedOdds: state.data.selectedOdds,
      betSelections: state.data.betSelections,
      nodeRequest: this.nodeSSR,
    });

    const mapDispatchToProps = {
      fetchData: fetchData,
      getEventDetails: getEventDetails,
      getBetsInfo: getBetsInfo,
      getOddsFormat: getOddsFormat,
      callBetslip: callBetslip,
    };

    PopularBets.serverFetch = {
      createStore,
      fetchData,
      strToTranslate: stringsToTranslate.concat([this.appConfig.title]),
    };

    return connect(mapStateToProps, mapDispatchToProps)(PopularBets);
  }
}

export default PopularBetsApp;
